import classNames from "classnames";
import "./ContentCenter.css";

export default function ContentCenter(props: {
    className?: string;
    children?: React.ReactNode;
}) {
    const { className, children } = props;
    return (
        <div className={classNames("web-lib__content-wrapper", className)}>
            {children}
        </div>
    );
}
