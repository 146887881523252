import classNames from "classnames";

function BaseImageAsset(src: string, alt?: string) {
    return function ImageAsset(props: { className?: string }) {
        const { className } = props;
        return (
            <div className={classNames("web-lib__asset", className)}>
                <img src={src} alt={alt ?? undefined} />
            </div>
        );
    };
}

const Asset = {
    AlbertineEmblem: BaseImageAsset(
        "https://ik.imagekit.io/albertine/share/albertine-emblem.svg",
        "Albertine",
    ),
    AlbertineTextLogo: BaseImageAsset(
        "https://ik.imagekit.io/albertine/share/albertine-logo-text.svg",
        "Albertine",
    ),
    AlbertineLogo: BaseImageAsset(
        "https://ik.imagekit.io/albertine/share/albertine-logo.svg",
        "Albertine",
    ),
};

export default Asset;
