/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import classNames from "classnames";
import React, { Ref, useImperativeHandle, useRef } from "react";
import "./Flex.css";

export type Gap =
    | "value4"
    | "value8"
    | "value12"
    | "value16"
    | "value20"
    | "value24"
    | "value28"
    | "value32"
    | "value36"
    | "value40";
export type AlignItems = "start" | "center" | "end" | "stretch";
export type AlignSelf = "start" | "center" | "end";
type JustifyContent =
    | "start"
    | "center"
    | "end"
    | "space-between"
    | "space-around"
    | "space-evenly";
type FlexWrap = "wrap" | "nowrap" | "wrap-reverse";

type FlexProps = {
    id?: string;
    className?: string;
    gap?: Gap;
    alignItems?: AlignItems;
    justifyContent?: JustifyContent;
    wrap?: FlexWrap;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    children: React.ReactNode;
};

function gapCss(gap?: Gap) {
    return gap ? `web-lib__flex-gap--${gap}` : undefined;
}

function alignCss(alignItems: AlignItems) {
    return `web-lib__flex-align--${alignItems}`;
}

function alignSelfCss(alignSelf: AlignSelf) {
    return `web-lib__flex-align-self--${alignSelf}`;
}

function justifyCss(justifyContent?: JustifyContent) {
    return justifyContent
        ? `web-lib__flex-justify--${justifyContent}`
        : undefined;
}

function wrapCss(wrap?: FlexWrap) {
    return wrap ? `web-lib__flex-wrap--${wrap}` : undefined;
}

const FlexRow = React.forwardRef(
    (props: FlexProps, ref: Ref<HTMLDivElement | null>) => {
        const {
            children,
            className,
            gap,
            alignItems = "center",
            justifyContent,
            wrap,
            id,
            onClick,
        } = props;

        const divRef = useRef<HTMLDivElement>(null);
        useImperativeHandle(ref, () => divRef.current);

        return (
            <div
                id={id}
                onClick={onClick}
                className={classNames(
                    "web-lib__flex-row",
                    gapCss(gap),
                    alignCss(alignItems),
                    justifyCss(justifyContent),
                    wrapCss(wrap),
                    className,
                )}
                ref={divRef}
            >
                {children}
            </div>
        );
    },
);

const FlexColumn = React.forwardRef(
    (props: FlexProps, ref: Ref<HTMLDivElement | null>) => {
        const {
            children,
            className,
            gap,
            alignItems = "start",
            justifyContent,
            wrap,
            id,
            onClick,
        } = props;

        const divRef = useRef<HTMLDivElement>(null);
        useImperativeHandle(ref, () => divRef.current);

        return (
            <div
                id={id}
                onClick={onClick}
                className={classNames(
                    "web-lib__flex-column",
                    gapCss(gap),
                    alignCss(alignItems),
                    justifyCss(justifyContent),
                    wrapCss(wrap),
                    className,
                )}
                ref={divRef}
            >
                {children}
            </div>
        );
    },
);

type InlineFlexProps = {
    className?: string;
    gap?: Gap;
    alignSelf?: AlignSelf;
    wrap?: FlexWrap;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    children: React.ReactNode;
};

export function FlexInline(props: InlineFlexProps) {
    const { className, children, gap, alignSelf, wrap, onClick } = props;

    return (
        <span
            className={classNames(
                "web-lib__flex-inline",
                className,
                gapCss(gap),
                alignCss("center"),
                alignSelfCss(alignSelf || "center"),
                wrapCss(wrap),
            )}
            onClick={onClick}
        >
            {children}
        </span>
    );
}

export const Flex = {
    Row: FlexRow,
    Column: FlexColumn,
};
