/* eslint-disable react/button-has-type */
import classNames from "classnames";
import React, { Ref, useImperativeHandle, useRef } from "react";
import "./Button.css";

type Props = {
    className?: string;
    children?: React.ReactNode;
    disabled?: boolean;
    label: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
} & Pick<React.ButtonHTMLAttributes<HTMLButtonElement>, "type">;

function BaseButton(baseClassName?: string) {
    return React.forwardRef(
        (props: Props, ref: Ref<HTMLButtonElement | null>) => {
            const { type, className, children, disabled, label, onClick } =
                props;

            const buttonRef = useRef<HTMLButtonElement>(null);
            useImperativeHandle(ref, () => buttonRef.current);

            return (
                <button
                    ref={buttonRef}
                    type={type || "button"}
                    className={classNames(
                        "web-lib__button-base",
                        baseClassName,
                        className,
                    )}
                    disabled={disabled}
                    onClick={(e) => {
                        e.stopPropagation();
                        if (disabled) return;
                        if (onClick) {
                            onClick(e);
                        }
                    }}
                >
                    {label}
                    {children}
                </button>
            );
        },
    );
}

const Button = Object.assign(BaseButton("web-lib__button"), {
    Primary: BaseButton("web-lib__button-primary"),
});

export default Button;
