import classNames from "classnames";
import "./Line.css";

function HorizontalLine(props: { className?: string }) {
    const { className } = props;
    return (
        <div
            className={classNames("web-lib__horizontal-line--solid", className)}
        />
    );
}

function VerticalLine(props: { className?: string }) {
    const { className } = props;
    return (
        <div
            className={classNames("web-lib__vertical-line--solid", className)}
        />
    );
}

function DashedHorizontal(props: { className?: string }) {
    const { className } = props;
    return (
        <div
            className={classNames(
                "web-lib__horizontal-line--dashed",
                className,
            )}
        />
    );
}

const Line = Object.assign(HorizontalLine, {
    Vertical: VerticalLine,
    Dashed: DashedHorizontal,
});

export default Line;
