import classNames from "classnames";
import "./Background.css";

export interface Props {
    className?: string;
    children: React.ReactNode;
}

function BaseBackground(baseClassName?: string) {
    return function Background(props: Props) {
        const { className, children } = props;
        return (
            <div
                className={classNames(
                    "web-lib__background",
                    baseClassName,
                    className,
                )}
            >
                {children}
            </div>
        );
    };
}

const Background = {
    Gray99: BaseBackground("web-lib__background--gray-99"),
    Gray80: BaseBackground("web-lib__background--gray-80"),
};

export default Background;
