import Asset from "../components/Asset";
import Background from "../components/Background";
import Button from "../components/Button";
import ContentCenter from "../components/ContentCenter";
import { Flex } from "../components/Flex";
import Line from "../components/Line";
import Paragraph from "../components/Paragraph";
import "./AlbertineLearnMore.css";

export default function AlbertineLearnMore() {
    return (
        <Background.Gray99>
            <ContentCenter className="albertine__learn-more">
                <Flex.Column alignItems="center" gap="value24">
                    <Flex.Column alignItems="center" gap="value24">
                        <Asset.AlbertineLogo />
                    </Flex.Column>

                    <Flex.Column alignItems="center" gap="value24">
                        <Paragraph.Large.Reqular.Gray10>
                            Albertine is an invitation-only concierge service
                            that accepts a limited number of members based on
                            referrals.
                        </Paragraph.Large.Reqular.Gray10>
                        <Line.Vertical />
                        <Button.Primary
                            label="Learn more & request an invite"
                            onClick={() =>
                                window.open(
                                    "https://www.albertineapp.com",
                                    "_blank",
                                )
                            }
                        />
                    </Flex.Column>
                </Flex.Column>
            </ContentCenter>
        </Background.Gray99>
    );
}
