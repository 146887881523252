import Paragraph from "../components/Paragraph";
import Tag from "../components/Tag";
import AlbertineFooter from "./AlbertineFooter";
import AlbertineLearnMore from "./AlbertineLearnMore";
import { Screen, AlbertineBackgroundImageContainer } from "./LandingScreen";
import "./NotFoundScreen.css";

export type NotFoundScreenProps = {
    title: string;
    description: string;
};

export default function NotFoundScreen(props: NotFoundScreenProps) {
    const { title, description } = props;
    return (
        <Screen>
            <AlbertineBackgroundImageContainer>
                <div className="not-found__details">
                    <Tag.Large className="not-found__tag">
                        Page not found
                    </Tag.Large>
                    <Paragraph.XLarge.Bold.Gray10>
                        {title}
                    </Paragraph.XLarge.Bold.Gray10>

                    <Paragraph.Large.Reqular.Gray10>
                        {description}
                    </Paragraph.Large.Reqular.Gray10>
                </div>
            </AlbertineBackgroundImageContainer>

            <AlbertineLearnMore />
            <AlbertineFooter />
        </Screen>
    );
}
