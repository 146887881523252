import classNames from "classnames";
import "./Label.css";

export interface Props {
    className?: string;
    fontStyle?: "italic";
    transform?: "uppercase";
    disabled?: boolean;
    children: React.ReactNode;
    nowrap?: boolean;
}

type BaseLabel = (className?: string) => (props: Props) => JSX.Element;

function BaseLabel(baseClassName?: string) {
    return function Label(props: Props) {
        const { className, children, fontStyle, transform, disabled, nowrap } =
            props;
        return (
            <span
                className={classNames(
                    "web-lib__label",
                    baseClassName,
                    {
                        "web-lib__label--disabled": disabled,
                        "web-lib__label--italic": fontStyle === "italic",
                        "web-lib__label--uppercase": transform === "uppercase",
                        "web-lib__label--nowrap": nowrap,
                    },
                    className,
                )}
            >
                {children}
            </span>
        );
    };
}

function Add(baseLabel: BaseLabel, className: string) {
    return (newClassName?: string) =>
        baseLabel(classNames(className, newClassName));
}

function Curry(baseLabel: BaseLabel) {
    return (className?: string) => baseLabel(className);
}

const Colors = (baseLabel: BaseLabel) =>
    Object.assign(baseLabel("web-lib__text-color--gray-10"), {
        Gray0: baseLabel("web-lib__text-color--gray-0"),
        Gray10: baseLabel("web-lib__text-color--gray-10"),
        Gray20: baseLabel("web-lib__text-color--gray-20"),
        Gray30: baseLabel("web-lib__text-color--gray-30"),
        Gray40: baseLabel("web-lib__text-color--gray-40"),
        Gray50: baseLabel("web-lib__text-color--gray-50"),
        Gray60: baseLabel("web-lib__text-color--gray-60"),
        Gray70: baseLabel("web-lib__text-color--gray-70"),
        Gray80: baseLabel("web-lib__text-color--gray-80"),
        Gray90: baseLabel("web-lib__text-color--gray-90"),
        Gray95: baseLabel("web-lib__text-color--gray-95"),
        Gray100: baseLabel("web-lib__text-color--gray-100"),
        PrimaryGreen60: baseLabel("web-lib__text-color--primary-green-60"),
        Brown60: baseLabel("web-lib__text-color--brown-60"),
        Brown80: baseLabel("web-lib__text-color--brown-80"),
        Brown95: baseLabel("web-lib__text-color--brown-95"),
        ForestGreen60: baseLabel("web-lib__text-color--forest-green-60"),
        ForestGreen80: baseLabel("web-lib__text-color--forest-green-80"),
        ForestGreen95: baseLabel("web-lib__text-color--forest-green-95"),
        Indigo60: baseLabel("web-lib__text-color--indigo-60"),
        Pink60: baseLabel("web-lib__text-color--pink-60"),
        Blue60: baseLabel("web-lib__text-color--blue-60"),
        Yellow60: baseLabel("web-lib__text-color--yellow-60"),
        Error60: baseLabel("web-lib__text-color--error-60"),
        Warning60: baseLabel("web-lib__text-color--warning-60"),
    });

const Weights = (baseComponent: BaseLabel) =>
    Object.assign(baseComponent(), {
        Reqular: Colors(Add(baseComponent, "web-lib__label--reqular")),
        MediumBold: Colors(Add(baseComponent, "web-lib__label--medium-bold")),
        Bold: Colors(Add(baseComponent, "web-lib__label--bold")),
        XBold: Colors(Add(baseComponent, "web-lib__label--xbold")),
        ...Colors(Curry(baseComponent)),
    });

const Sizes = (baseComponent: BaseLabel) =>
    Object.assign(baseComponent(), {
        Large: Weights(Add(baseComponent, "web-lib__label--large")),
        Medium: Weights(Add(baseComponent, "web-lib__label--medium")),
        Small: Weights(Add(baseComponent, "web-lib__label--small")),
        XSmall: Weights(Add(baseComponent, "web-lib__label--xsmall")),
    });

const Label = Sizes(BaseLabel);

export default Label;
