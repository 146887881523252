import "../css/reset.css";
import "../css/fonts.css";
import "../css/variables.css";
import ReactDOM from "react-dom";
import templatesConfig from "./templatesConfig";
import NotFoundScreen from "../screens/NotFoundScreen";

document.addEventListener("DOMContentLoaded", () => {
    ReactDOM.hydrate(
        <NotFoundScreen
            title="Looks like this content has expired"
            description="For security reasons, shared content expires after 90 days"
        />,
        document.getElementById(templatesConfig.reactRootId),
    );
});
