import { Flex } from "../components/Flex";
import Label from "../components/Label";
import "./AlbertineFooter.css";

export default function AlbertineFooter() {
    const currentYear = new Date().getFullYear();
    return (
        <div className="albertine-footer">
            <Flex.Column
                alignItems="center"
                className="albertine-footer__container"
                gap="value8"
            >
                <Label.XSmall.Reqular.Gray30>
                    &copy; Albertine, {currentYear}. All rights reserved.
                </Label.XSmall.Reqular.Gray30>
                <Label.XSmall.Reqular.Gray30>
                    <a href="mailto:membership@albertineapp.com">
                        membership@albertineapp.com
                    </a>
                </Label.XSmall.Reqular.Gray30>
            </Flex.Column>
        </div>
    );
}
