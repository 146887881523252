import classNames from "classnames";
import "./Paragraph.css";

export interface Props {
    className?: string;
    fontStyle?: "italic";
    transform?: "uppercase";
    children: React.ReactNode;
    disabled?: boolean;
}

type BaseParagraph = (className?: string) => (props: Props) => JSX.Element;

function BaseParagraph(baseClassName?: string) {
    return function Paragraph(props: Props) {
        const { className, fontStyle, transform, children, disabled } = props;
        return (
            <p
                className={classNames(
                    "web-lib__paragraph",
                    baseClassName,
                    {
                        "web-lib__paragraph--italic": fontStyle === "italic",
                        "web-lib__paragraph--uppercase":
                            transform === "uppercase",
                        "web-lib__paragraph--disabled": disabled,
                    },
                    className,
                )}
            >
                {children}
            </p>
        );
    };
}

function Add(baseParagraph: BaseParagraph, className: string) {
    return (newClassName?: string) =>
        baseParagraph(classNames(className, newClassName));
}

function Curry(baseParagraph: BaseParagraph) {
    return (className?: string) => baseParagraph(className);
}

const Colors = (baseParagraph: BaseParagraph) =>
    Object.assign(baseParagraph("web-lib__text-color--gray-10"), {
        Gray0: baseParagraph("web-lib__text-color--gray-0"),
        Gray10: baseParagraph("web-lib__text-color--gray-10"),
        Gray20: baseParagraph("web-lib__text-color--gray-20"),
        Gray30: baseParagraph("web-lib__text-color--gray-30"),
        Gray40: baseParagraph("web-lib__text-color--gray-40"),
        Gray50: baseParagraph("web-lib__text-color--gray-50"),
        Gray60: baseParagraph("web-lib__text-color--gray-60"),
        Gray70: baseParagraph("web-lib__text-color--gray-70"),
        Gray80: baseParagraph("web-lib__text-color--gray-80"),
        Gray90: baseParagraph("web-lib__text-color--gray-90"),
        Gray95: baseParagraph("web-lib__text-color--gray-95"),
        Gray100: baseParagraph("web-lib__text-color--gray-100"),
        PrimaryGreen60: baseParagraph("web-lib__text-color--primary-green-60"),
        Brown60: baseParagraph("web-lib__text-color--brown-60"),
        Brown80: baseParagraph("web-lib__text-color--brown-80"),
        Brown95: baseParagraph("web-lib__text-color--brown-95"),
        ForestGreen60: baseParagraph("web-lib__text-color--forest-green-60"),
        ForestGreen80: baseParagraph("web-lib__text-color--forest-green-80"),
        ForestGreen95: baseParagraph("web-lib__text-color--forest-green-95"),
        Indigo60: baseParagraph("web-lib__text-color--indigo-60"),
        Pink60: baseParagraph("web-lib__text-color--pink-60"),
        Blue60: baseParagraph("web-lib__text-color--blue-60"),
        Yellow60: baseParagraph("web-lib__text-color--yellow-60"),
        Error60: baseParagraph("web-lib__text-color--error-60"),
        Warning60: baseParagraph("web-lib__text-color--warning-60"),
    });

const Weights = (baseParagraph: BaseParagraph) =>
    Object.assign(baseParagraph(), {
        Reqular: Colors(Add(baseParagraph, "web-lib__paragraph--reqular")),
        MediumBold: Colors(
            Add(baseParagraph, "web-lib__paragraph--medium-bold"),
        ),
        Bold: Colors(Add(baseParagraph, "web-lib__paragraph--bold")),
        XBold: Colors(Add(baseParagraph, "web-lib__paragraph--xbold")),
        ...Colors(Curry(baseParagraph)),
    });

const Sizes = (baseParagraph: BaseParagraph) =>
    Object.assign(baseParagraph(), {
        XXLarge: Weights(Add(baseParagraph, "web-lib__paragraph--xxlarge")),
        XLarge: Weights(Add(baseParagraph, "web-lib__paragraph--xlarge")),
        Large: Weights(Add(baseParagraph, "web-lib__paragraph--large")),
        Medium: Weights(Add(baseParagraph, "web-lib__paragraph--medium")),
        Small: Weights(Add(baseParagraph, "web-lib__paragraph--small")),
        XSmall: Weights(Add(baseParagraph, "web-lib__paragraph--xsmall")),
    });

const Paragraph = Sizes(BaseParagraph);

export default Paragraph;
