import React from "react";
import { Flex } from "../components/Flex";
import "./LandingScreen.css";

export function Screen(props: { children: React.ReactNode }) {
    const { children } = props;
    return <div className="web-lib__screen">{children}</div>;
}

export function AlbertineBackgroundImageContainer(props: {
    children: React.ReactNode;
}) {
    const { children } = props;
    return (
        <Flex.Column
            justifyContent="center"
            className="web-lib__screen__container web-lib__screen__albertine-background"
        >
            {children}
        </Flex.Column>
    );
}
