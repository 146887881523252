import React from "react";
import classNames from "classnames";
import "./Tag.css";

type Props = {
    className?: string;
    children: React.ReactNode;
};

type BaseTag = (className?: string) => (props: Props) => JSX.Element;

function BaseTag(baseClassName?: string) {
    return function Tag(props: Props) {
        const { className, children } = props;
        return (
            <span
                className={classNames("web-lib__tag", baseClassName, className)}
            >
                {children}
            </span>
        );
    };
}

const Sizes = (baseComponent: BaseTag) =>
    Object.assign(baseComponent(""), {
        Large: baseComponent("web-lib__tag--large"),
    });

const Tag = Sizes(BaseTag);

export default Tag;
